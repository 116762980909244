import {useStore} from 'vuex';

const can = (permission) => {
  const store = useStore()
  const user = store.getters['auth/user']
  if (user) {
    return ['ADMINISTRADOR', ...permission].some(permiso => {
      return user.hasPermisos.map(e => e.nombre).includes(permiso.toUpperCase())
    })  
  } else {
    return false
  }
}

const install = app => {
  app.config.globalProperties.$can = can
}

export { install as default, can }