import { createRouter, createWebHistory } from 'vue-router'
import authRouter from '../views/auth/router'
import checkAuth from '../middleware/checkAuth.middleware'
import prestadoresRouter from '../views/prestadores/router'
import documentosListar from '../views/documentos/router'
import auth from '../middleware/auth.middleware'
import configuraciones from '../views/config/router'
import glosas from '../views/glosas/router'
import precioslRouter from '../views/preciosL/routes'
import preciosNewRouter from '../views/newPrecios/routes'
import mesaAyuda from '../views/mesaAyuda/router'
import reportes from '../views/reportes/router'
import notaTecnica from '../views/notaTecnica/router'
import conciliaciones from '../views/conciliaciones/router'
import contratos from '../views/contratos/routes'

const routes = [
  ...authRouter,
  ...prestadoresRouter,
  ...documentosListar,
  ...configuraciones,
  ...precioslRouter,
  ...mesaAyuda,
  ...glosas,
  ...reportes,
  ...notaTecnica,
  ...conciliaciones,
  ...preciosNewRouter,
  ...contratos,
  {
    path: '/',
    name: 'Home',
    beforeEnter: [auth],
    component: () => import('../views/Home.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  
})

router.beforeEach(checkAuth)
router.beforeEach((to)=>{
  const {title} = to.meta
  document.title = `${title|| 'INICIO'} | DAILYTECH`
})

export default router
