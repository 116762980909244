import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './libs'
import PrimeVue from 'primevue/config'
// import Tooltip from 'primevue/tooltip'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
// import 'primevue/resources/themes/tailwind-light/theme.css';
import './assets/css/tailwind-light/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './assets/tailwind.css';
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'

import utils from './utils'
import MessageError from './components/Messages/Error'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faLock, faEnvelope, faUser, faEraser, faBuilding, faLifeRing,
  faVideo, faTicketAlt, faExclamationCircle, faCloud, faDownload,
  faExclamationTriangle, faCheckCircle, faTags, faInfoCircle, faThumbsUp,
  faFilePdf, faPaperPlane, faAsterisk, faReply, faHandshake, faFileExcel, faFileContract
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
//Traducciones
import local from "@/libs/primevue";

library.add(faLock, faEnvelope, faUser, faEraser, faBuilding, faLifeRing, 
  faVideo, faTicketAlt, faExclamationCircle, faCloud, faDownload,
  faExclamationTriangle, faCheckCircle, faTags, faInfoCircle, faThumbsUp,
  faFilePdf, faPaperPlane, faAsterisk, faReply, faHandshake, faFileExcel, faFileContract)

const app = createApp(App).use(store).use(router).use(PrimeVue,{locale: local}).use(ToastService).use(VueSidebarMenu).use(FloatingVue)
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('MessageError', MessageError)
app.component('Toast', Toast)
// app.directive('tooltip', Tooltip);
utils(app)

app.mount('#app')