import auth from '../../middleware/auth.middleware'
export default [
  {
    path: '/prestadores/form/:id?',
    name: 'prestadores.form',
    beforeEnter: [auth],
    component: ()=>import('./form'),
    meta:{
      title: 'PRESTADORES FORMULARIO'
    }
  },
  {
    path: '/prestadores/:id',
    name: 'prestadores.ver',
    component: ()=> import('./ver'),
    beforeEnter: [auth],
    meta:{
      title: 'PRESTADORES VER'
    }
  },
  {
    path: '/prestadores',
    name: 'prestadores.listar',
    component: ()=>import('./listar'),
    beforeEnter: [auth],
    meta:{
      title: 'PRESTADORES'
    }
  }
]