import auth from '../../middleware/auth.middleware'

export default [
  {
    path: '/soporte',
    name: 'support',
    beforeEnter: [auth],
    component: ()=>import('./index'),
    meta:{
      title: 'TUTORIALES'
    }
  }
]