import Cookies from 'js-cookie'
import axios from '../../libs/axios.lib'
import jwt_decode from 'jwt-decode'
import dayjs from 'dayjs'


const state = () => {
  return {
    user: null,
    token: Cookies.get('token'),
    refreshToken: Cookies.get('refreshToken'),
    refreshTask: 0
  }
}

// getters
const getters = {
  token: state => state.token,
  user: state => state.user,
  check: state => state.user !== null
  
}

// actions
const actions = {
  saveToken ({ commit}, payload) {
    commit('SAVE_TOKEN', payload)
  },
  async fetchUser ({ commit }) {
    try {
      const { data } = await axios.get('/auth/me',{headers:{loading:'true'}})
      commit('FETCH_USER_SUCCESS', { user: data })
    } catch (e) {
      commit('FETCH_USER_FAILURE')
    }
  },
  async logout ({ commit }) {
    try {
      await axios.post('/api/logout',{headers:{loading:'true'}})
    } catch (e) {
      console.error(e)
    }

    commit('LOGOUT')
  },
  refreshTokens({commit,state,dispatch}){    
    const {refreshToken} = state
    axios.post('/auth/refreshtoken',{ refreshToken },{headers:{loading:'true'}}).then(({data})=>{
      const {accessToken: token, refreshToken} = data
      commit('SAVE_TOKEN',{ token, refreshToken })
      dispatch('autoRefresh')
    }).catch(err=>{
      console.error(err.message)
    })
  },
  autoRefresh({state,commit,dispatch}){
    const {token} = state
    const {exp} = jwt_decode(token)
    const now = dayjs(new Date())
    const fechaExp = dayjs( new Date(exp*1000) )
    let timeUntilRefresh = fechaExp.diff(now,'s') 
    timeUntilRefresh -= (1 * 60)    
    if(timeUntilRefresh <= 0) return false

    const refreshTask = setTimeout(() => dispatch('refreshTokens'),timeUntilRefresh * 1000)
    commit('refreshTask',refreshTask)
  }
}

// mutations
const mutations = {
  SAVE_TOKEN(state, { token, remember, refreshToken  }) {
    state.token = token
    state.refreshToken  = refreshToken
    Cookies.set('token', token, { expires: remember ? 365 : null })
    Cookies.set('refreshToken', refreshToken, { expires: 3600 })
  },
  LOGOUT (state) {
    state.user = null
    state.token = null

    Cookies.remove('token')
    Cookies.remove('refreshToken')
  },
  FETCH_USER_SUCCESS(state, { user }) {
    let userData = user
    userData['hasPermisos'] = userData.hasPermisos.map(permiso => {
      return{
        id: permiso.permisosId,
        nombre: permiso.myPermisos.nombre,
      }
    })
    state.user = userData
  },
  FETCH_USER_FAILURE (state) {
    state.token = null
    Cookies.remove('token')
  },
  refreshTask(state,payload){
    if(state.refreshTask) clearInterval(state.refreshTask)
    state.refreshTask = payload
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}