import authMiddleware from '@/middleware/auth.middleware'
import auth from '@/middleware/auth.middleware';

export default [
  {
    path: '/contratos',
    name: 'contratos.listar',
    component: () => import('./listar/index.vue'),
    beforeEnter: [authMiddleware],
    meta:{
      title: 'CONTRATOS'
    }
  },
  {
    path: '/contratos/form/:id?',
    name: 'contratos.form',
    beforeEnter: [auth],
    component: ()=>import('./form/index.vue'),
    meta:{
      title: 'CONTRATOS FORMULARIO'
    }
  },
  {
    path: '/contratos/:id',
    name: 'contratos.ver',
    component: ()=> import('./ver/index.vue'),
    beforeEnter: [auth],
    meta:{
      title: 'CONTRATOS VER'
    }
  }
]