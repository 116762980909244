
const state = () => {
  return {
    isCollapsed: false,
    isOnMobile: false,
  }
}

// getters
const getters = {
  collapsed: state => state.isCollapsed,
  isMobile: state => state.isOnMobile
}

// actions
const actions = {
  async getCollapsed (context, payload) {
    const { commit } = context
    await commit('setCollapsed', payload)
  },
  async getIsMobile (context, payload) {
    const { commit } = context
    await commit('setIsMobile', payload)
  }
}

// mutations
const mutations = {
  setCollapsed (state, payload) {
    state.isCollapsed = payload
  },
  setIsMobile (state, payload) {
    state.isOnMobile = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}