import auth from '../../middleware/auth.middleware'

export default [
  // {
  //   path: '/rip/:id',
  //   name: 'rip.ver',
  //   beforeEnter: [auth],
  //   component: ()=>import('./ripVer'),
  //   meta:{
  //     title: 'VER RIP'
  //   }
  // },
  {
    path: '/rip/:id/:facturaNumero',
    name: 'rip.ver',
    beforeEnter: [auth],
    component: () => import('./ripVer'),
    meta: {
      title: 'VER RIP'
    }
  },
  {
    path: '/rip/:id/af',
    name: 'rip.af.ver',
    beforeEnter: [auth],
    component: () => import('./ripsAfVer'),
    meta:{
      title: 'VER RIPS AF'
    }
  },
  {
    path: '/documentos/logs/:id',
    name: 'documentos.logs.ver',
    component: ()=> import('./logs'),
    beforeEnter: [auth],
    meta:{
      title: 'INFO LOGS'
    }
  },
  {
    path: '/documentos',
    name: 'documentos.listar',
    beforeEnter: [auth],
    component: ()=>import('./listar'),
    meta:{
      title: 'BUSCAR CARGAS'
    }
  },
  {
    path: '/documentos/cargar',
    name: 'documentos.cargar',
    beforeEnter: [auth],
    component: ()=>import('./cargar'),
    meta:{
      title: 'CARGAR RIP'
    }
  },
  {
    path: '/documentos/tipificar/:id',
    name: 'documentos.tipificar',
    beforeEnter: [auth],
    component: ()=>import('./tipificar'),
    meta:{
      title: 'TIPIFICAR'
    }
  },
  {
    path: '/documentos/auditar/:id',
    name: 'documentos.auditar',
    beforeEnter: [auth],
    component: ()=>import('./auditar'),
    meta:{
      title: 'AUDITAR'
    }
  },
]