import axios from '../../libs/axios.lib'
const state = () => {
  return {
    _facturas: [],
    _selectedFacturas: [],
    _loading: false
  }
}

// getters
const getters = {
  facturas: state => state._facturas,
  selectedFacturas: state => state._selectedFacturas,
  loading: state => state._loading
}

// actions
const actions = {
  getValidarFacturasContratoEps  ({ commit },payload) {
    return new Promise((resolve) => {
      axios.get(`/documentos/validar-facturas-contrato-eps/${payload}`,{headers:{loading:true}}).then(({data}) => {
        commit('setValidarFacturasContratoEps', data)
        commit('setSelectedFacturas', [])
        resolve(data)
      })
    })
  }
}

// mutations
const mutations = {
  setValidarFacturasContratoEps(state,payload){
    state._facturas = payload
  },
  setSelectedFacturas(state,payload){
    state._selectedFacturas = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}