import axios from 'axios'
import Swal from 'sweetalert2'
import store from '../store'
import router from '../router'
// import i18n from '~/plugins/i18n'

axios.defaults.baseURL = process.env.VUE_APP_HOST_API

// Request interceptor
axios.interceptors.request.use(request => {
  const {loading} =  request.headers
  if(!loading) document.body.classList.add('loading-indicator');
  const token = store.getters['auth/token']
  if (token) {
    request.headers.common.Authorization = `Bearer ${token}`
  }
  // const locale = store.getters['lang/locale']
  // if (locale) {
  //   request.headers.common['Accept-Language'] = locale
  // }

  // request.headers['X-Socket-Id'] = Echo.socketId()

  return request
})

// Response interceptor
axios.interceptors.response.use(response => {
  document.body.classList.remove('loading-indicator');
  return response
}, error => {
  document.body.classList.remove('loading-indicator');
  const { status } = error.response
  if (status === 401 && store.getters['auth/check']) {
    store.commit('auth/LOGOUT')
    router.push({ name: 'auth.login' })
    Swal.fire({
      icon: 'warning',
      title: 'Mensaje',
      text: 'La sesión ha expirado',
    })
  } else if(status === 403){
    store.commit('auth/LOGOUT')
    router.push({ name: 'auth.login' })
    Swal.fire({
      icon: 'warning',
      title: 'Mensaje',
      text: 'La sesión ha expirado',
    })
  } else if (status === 429) {
    Swal.fire({
      title: 'Mensaje de error',
      icon: 'error',
      text: 'Demasiados ventanas abiertas, intente más tarde',
    })
  } else if (status >= 400) {
    Swal.fire({
      title: 'Mensaje de error',
      icon: 'error',
      text: error.response.data.message
    })
  } else if (status >= 500) {
    serverError(error.response)
  }
  

  return Promise.reject(error)
})

let serverErrorModalShown = false
async function serverError (response) {
  if (serverErrorModalShown) {
    return
  }

  if ((response.headers['content-type'] || '').includes('text/html')) {
    const iframe = document.createElement('iframe')

    if (response.data instanceof Blob) {
      iframe.srcdoc = await response.data.text()
    } else {
      iframe.srcdoc = response.data
    }

    Swal.fire({
      html: iframe.outerHTML,
      showConfirmButton: false,
      customClass: { container: 'server-error-modal' },
      didDestroy: () => { serverErrorModalShown = false },
      grow: 'fullscreen',
      padding: 0
    })

    serverErrorModalShown = true
  } else {
    // Swal.fire({
    //   icon: 'error',
    //   title: i18n.t('error_alert_title'),
    //   text: i18n.t('error_alert_text'),
    //   reverseButtons: true,
    //   confirmButtonText: i18n.t('ok'),
    //   cancelButtonText: i18n.t('cancel')
    // })
  }
}

export default axios