import auth from '../../middleware/auth.middleware'

export default [
  {
    path: '/reportes',
    name: 'reportes',
    beforeEnter: [auth],
    component: ()=>import('./listar'),
    meta:{
      title: 'REPORTES'
    }
  }
]