<template>
  <router-view />
</template>

<style lang="scss">
#app {
  font-family: 'Segoe UI', sans-serif;
  font-size: 14px;
}
</style>
