import auth from '../../middleware/auth.middleware'

export default [
  {
    path: '/conciliaciones',
    name: 'conciliaciones',
    beforeEnter: [auth],
    component: ()=>import('./listar'),
    meta:{
      title: 'CONCILIACIONES'
    }
  },
  {
    path: '/conciliaciones-detalle/:numRad',
    name: 'conciliacionesDetalle',
    beforeEnter: [auth],
    component: ()=>import('./listar/conciliacionesDetalle.vue'),
    meta:{
      title: 'CONCILIACIONES DETALLE'
    }
  }
]