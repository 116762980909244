import auth from '../../middleware/auth.middleware'

export default [
  {
    path: '/nota-tecnica/vizualizar',
    name: 'nota-tecnica.visualizar',
    beforeEnter: [auth],
    component: ()=>import('./vizualizar'),
    meta:{
      title: 'NOTA TÉCNICA',
    }
  },
  {
    path: '/nota-tecnica/rips',
    name: 'nota-tecnica.rips',
    redirect: '/nota-tecnica/rips/facturas',
    beforeEnter: [auth],
    component: ()=>import('./rips'),
    meta:{
      title: 'NOTA TÉCNICA',
    },
    children: [
      {
        path: 'facturas',
        name: 'nota-tecnica.rips.factura',
        beforeEnter: [auth],
        component: ()=>import('./rips/facturas'),
        meta:{
          title: 'NOTA TÉCNICA',
        }
      },{
        path: 'consultas',
        name: 'nota-tecnica.rips.consultas',
        beforeEnter: [auth],
        component: ()=>import('./rips/consultas'),
        meta:{
          title: 'NOTA TÉCNICA',
        }
      },{
        path: 'procedimientos',
        name: 'nota-tecnica.rips.procedimientos',
        beforeEnter: [auth],
        component: ()=>import('./rips/procedimientos'),
        meta:{
          title: 'NOTA TÉCNICA',
        }
      },{
        path: 'medicamentos',
        name: 'nota-tecnica.rips.medicamentos',
        beforeEnter: [auth],
        component: ()=>import('./rips/medicamentos'),
        meta:{
          title: 'NOTA TÉCNICA',
        }
      },{
        path: 'insumos',
        name: 'nota-tecnica.rips.insumos',
        beforeEnter: [auth],
        component: ()=>import('./rips/insumos'),
        meta:{
          title: 'NOTA TÉCNICA',
        }
      },
    ]
  }
]