import auth from '../../middleware/auth.middleware'

export default [
  {
    path: '/glosas',
    name: 'glosas',
    beforeEnter: [auth],
    component: ()=>import('./listar/newIndex.vue'),
    meta:{
      title: 'GLOSAS'
    }
  },
  {
    path: '/glosas-detalle/:numRad',
    name: 'glosas.detalle',
    beforeEnter: [auth],
    component: ()=>import('./listar'),
    meta:{
      title: 'GLOSAS DETALLE'
    }
  },
  {
    path: '/glosas/:numRad',
    name: 'glosas.ver',
    beforeEnter: [auth],
    component: ()=>import('./detalle'),
    meta:{
      title: 'VER GLOSAS'
    }
  },
  {
    path: '/glosas/respuesta/:codigoGlosa',
    name: 'glosas.respuesta',
    beforeEnter: [auth],
    component: ()=>import('./respuesta'),
    meta:{
      title: 'VER DETALLE GLOSA Y RESPUESTA'
    }
  },
  {
    path: '/glosas/dashboard',
    name: 'dashboard-glosas',
    beforeEnter: [auth],
    component: ()=>import('./dashboard/index.vue'),
    meta:{
      title: 'DASHBOARD'
    }
  },
  {
    path: '/glosas/ver/respuesta/:codigoGlosa',
    name: 'glosas.ver.respuesta',
    beforeEnter: [auth],
    component: ()=>import('./respuestaGlosa'),
    meta:{
      title: 'VER DETALLE GLOSA Y RESPUESTA'
    }
  },
  {
    path: '/glosas/generacion-glosas/regulados',
    name: 'generacion-glosas-regulados',
    beforeEnter: [auth],
    component: ()=>import('./generacionGlosas/regulados/index.vue'),
    meta:{
      title: 'REGULADOS'
    }
  },
  {
    path: '/glosas/generacion-glosas/pacientes',
    name: 'generacion-glosas-pacientes',
    beforeEnter: [auth],
    component: ()=>import('./generacionGlosas/pacientes/index.vue'),
    meta:{
      title: 'PACIENTES'
    }
  },
  {
    path: '/glosas/generacion-glosas/tarifa',
    name: 'generacion-glosas-tarifas',
    beforeEnter: [auth],
    component: ()=>import('./generacionGlosas/tarifas'),
    meta:{
      title: 'GENERACIÓN GLOSAS | PACIENTES'
    }
  },
  {
    path: '/glosas/gestion-glosas',
    name: 'gestion-glosas',
    beforeEnter: [auth],
    component: ()=>import('./gestionGlosas/index.vue'),
    meta:{
      title: 'GESTION GLOSAS'
    }
  }
]