import authMiddleware from '@/middleware/auth.middleware'

export default [
  {
    path: '/preciosl/form',
    name: 'preciosl.form',
    component: () => import('./form/index.vue'),
    beforeEnter: [authMiddleware],
    meta:{
      title: 'FORMULARIO LISTA PRECIOS'
    }
  },
  {
    path: '/preciosl/:id',
    name: 'preciosl.ver',
    component: () => import('./ver/index.vue'),
    beforeEnter: [authMiddleware],
    meta:{
      title: 'LISTA PRECIOS'
    }
  },
  {
    path: '/preciosl',
    name: 'preciosl.listar',
    component: () => import('./listar/index.vue'),
    beforeEnter: [authMiddleware],
    meta:{
      title: 'LISTA PRECIOS'
    }
  }
]